import { createApp } from 'vue'
import App from './App.vue'

import { createPinia } from 'pinia'
import router from './router'

import vuetify from './utilities/vuetify'

//NOTE - Toastification is a library for displaying toasts in Vue. It is used to display success and error messages to the user.
import Toast, { POSITION, PluginOptions } from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import { createI18n, I18nOptions } from 'vue-i18n'
import { locales, defaultLocale as locale } from '@/assets/i18n'
import * as Sentry from '@sentry/vue'
// ERRORS
// import handleAlerts from './utilities/handleAlerts'

// ANCHOR Formkit
import { defaultConfig, plugin } from '@formkit/vue'
import { nl } from '@formkit/i18n'
import { en } from '@formkit/i18n'
import { createProPlugin, datepicker, colorpicker, dropdown, autocomplete, taglist, slider, toggle, rating, transferlist, togglebuttons } from '@formkit/pro'
import '@formkit/addons/css/multistep'
import './assets/scss/style.scss'

import 'trumbowyg/dist/plugins/emoji/ui/trumbowyg.emoji.min.css'
import 'trumbowyg/dist/plugins/emoji/trumbowyg.emoji.min.js'

import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.min.css'
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.min.js'
import 'trumbowyg/dist/plugins/cleanpaste/trumbowyg.cleanpaste.min.js'

const pro = createProPlugin('fk-5a6b0ec790', {
    dropdown,
    datepicker,
    colorpicker,
    autocomplete,
    taglist,
    slider,
    toggle,
    rating,
    transferlist,
    togglebuttons,
})

const pinia = createPinia()
const app = createApp(App)

const toastOptions: PluginOptions = {
    position: POSITION.BOTTOM_CENTER,
    closeOnClick: true,
    pauseOnHover: true,
}

Sentry.init({
    app,
    dsn: 'https://3925115aeb6998395132487e9599c435@o4506744415846400.ingest.sentry.io/4506744417091584',
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    release: import.meta.env.VITE_BUILD,
    environment: import.meta.env.VITE_X_BRANCH,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/db\.contentkalender\.app/],
    // Session Replay
    replaysSessionSampleRate: import.meta.env.VITE_SENTRY_REPLAY_RATE || 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_REPLAY_ERRORRATE || 0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const i18nOptions: I18nOptions = {
    legacy: false,
    locale,
    fallbackLocale: 'nl',
    messages: locales.value,
    warnHtmlInMessage: 'off',
}

const i18n = createI18n<false, typeof i18nOptions>(i18nOptions)

app.use(
    plugin,
    defaultConfig({
        theme: 'genesis',
        plugins: [pro],
        locales: { nl, en },
        locale: 'en',
    })
)
app.use(Toast, toastOptions)
app.use(i18n)
app.use(router)
app.use(pinia)
app.use(vuetify)
app.mount('#app')
