import apiCall from './apiCall'
import type { RequestBody, ResponseType } from './apiCall'

type ArrElement<ArrType> = ArrType extends (infer ElementType)[] ? ElementType : never

type CreateAuthLogin = RequestBody<'/auth/login', 'post'>
type CreateAuthLoginPublic = RequestBody<'/auth/login/public', 'post'>
type AuthLoginPublic = ResponseType<'/auth/login/public', 'post'>
type CreateAuthBody = RequestBody<'/auth/me', 'post'>

function getAuth(login: CreateAuthLogin) {
    return apiCall('/auth/login', 'post', null, login)
}

async function getAuthPublic(login: CreateAuthLoginPublic) {
    return await apiCall('/auth/login/public', 'post', null, login)
}

async function getAuthSso(uuid: string) {
    if (!uuid) return null
    return await apiCall(`/sso/token/${uuid}`, 'get', null, null)
}

async function apiUpdateAuth(body: CreateAuthBody) {
    return await apiCall('/auth/me', 'post', null, body)
}

function getBypass(id: number) {
    return apiCall('/auth/bypass', 'post', null, {
        code: import.meta.env.VITE_BYPASS_CODE,
        userId: id,
    })
}

type Me = ResponseType<'/auth/me', 'get'>
function getMe() {
    return apiCall('/auth/me', 'get', null, null)
}

type LanguageResponse = ResponseType<'/language', 'get'>
type LanguageItem = ArrElement<LanguageResponse['items']>
function apiGetLanguage() {
    return apiCall('/language', 'get', {}, null)
}

type CountryResponse = ResponseType<'/country', 'get'>
type CountryItem = ArrElement<CountryResponse['items']>
function apiGetCountry() {
    return apiCall('/country', 'get', {}, null)
}

// type Check = ResponseType<'/auth/check', 'get'>
async function apiGetCheck(email: string) {
    return await apiCall('/auth/check', 'get', { email }, null)
}

async function apiGetTwoFactor() {
    return await apiCall('/auth/twofactor', 'get', null, null)
}

async function apiSetTwoFactor(twofactor: string) {
    return await apiCall('/auth/twofactor', 'post', null, { twofactor })
}

type CreateAuthResetRequest = RequestBody<'/auth/password/reset_request', 'post'>
function apiRequestPassword(body: CreateAuthResetRequest) {
    return apiCall('/auth/password/reset_request', 'post', null, body)
}

type CreateAuthPasswordReset = RequestBody<'/auth/password/reset', 'post'>
function apiResetPassword(body: CreateAuthPasswordReset) {
    return apiCall('/auth/password/reset', 'post', null, body)
}

type PermissionRequestBody = RequestBody<'/auth/permission', 'post'>
export type PermissionType = Exclude<PermissionRequestBody['table'], 'flags'>
export type PermissionAction = Extract<PermissionRequestBody['action'], 'create' | 'read' | 'update' | 'delete'>
export type PermissionFlag =
    | 'canUseWorkflows'
    | 'partnershipFunctionality'
    | 'canAccessCalendarFeed'
    | 'canUseKanban'
    | 'canSelectCustomColor'
    | 'communicationContentAndFiles'
    | 'departmentsFunctionality'
    | 'handleEnterpriseDepartments'
    | 'maxNumberOfCommunications'
    | 'grippAccess'
    | 'maxUsers'
    | 'enableTaskExtended'

export type UserFlag = 'canEditSettings' | 'canExport' | 'canBulkEdit' | 'canMergeMeta'

export function getAllPermissions() {
    return apiCall('/auth/session', 'get', null, null) as Promise<{
        permissions: {
            [key in PermissionType]: PermissionAction[]
        } & { flags: UserFlag[] }
        authorization: {
            [key in PermissionFlag]: boolean
        }
        subscription: string
        impersonation: boolean
    }>
}

export function getPermission(body: PermissionRequestBody) {
    return apiCall('/auth/permission', 'post', null, body)
}

export {
    getAuth,
    getAuthPublic,
    getAuthSso,
    getBypass,
    getMe,
    apiUpdateAuth,
    apiGetLanguage,
    apiGetCountry,
    apiGetTwoFactor,
    apiSetTwoFactor,
    type Me,
    type CreateAuthBody,
    type LanguageItem,
    type CountryItem,
    // type Check,
    apiGetCheck,
    type CreateAuthResetRequest,
    apiRequestPassword,
    type CreateAuthPasswordReset,
    apiResetPassword,
    type AuthLoginPublic,
}
