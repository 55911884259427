import { defineStore } from 'pinia'
import { ref, watch, reactive, computed, type ComputedRef, onMounted } from 'vue'
import { useLocalStorage } from '@vueuse/core'
import refreshProgress from '@/utilities/refreshProgress' // loader utilities
import { useCalendarStore } from './calendarStore'

//REVIEW CRUQ
// REVIEW CRUQ Mediachannel nieuwe typing als standaard doorgeven fout, waarom?
import { type Communication as ComRoot, type Mediachannel, type Contenttype, type Audience, type Target, type Label, type Request, type Holiday } from '@api/_apiTypes'
import {
    apiGetCommunications,
    apiGetRequests,
    apiCopyCommunication,
    apiDoneCommunication,
    apiToGripp,
    apiReplaceCommunicationWorkflow,
    apiGetMetaIcons,
    type CommunicationItem,
    type MetaIconItem,
} from '@/api/communication'
import { apiGetContentTypes, type ContentTypeItem } from '@api/contenttype'
import { apiGetMediaChannels, type MediaChannelItem } from '@api/mediachannel'
import { apiGetAudiences, type AudienceItem } from '@api/audience'
import { apiGetTargets, type TargetItem } from '@api/target'
import { apiGetLabels, type LabelItem } from '@api/label'
import { apiGetHolidays } from '@api/holiday'

import {
    apiGetCommunicationstatus,
    apiUpdateCommunicationstatusById,
    apiCreateCommunicationstatus,
    apiDeleteCommunicationstatusById,
    type CommunicationstatusItem,
    type CreateCommunicationstatusBody,
} from '@api/communicationstatus'

// import { getContentTypes, type ContentTypeItem, type CreateContentTypeBody,} from '@/api/contenttype'

export const useCommunicationStore = defineStore('communication', () => {
    // ANCHOR States - - - - - - - - - - - -
    // Communication type is a combination of the root and the colorCode from the project.
    // This breaks with the convention of type Communication, this is an inherent shortcoming of Xano Swagger doc
    type Communication = ComRoot & { colorCode?: string }
    const communications = ref<Communication[]>([])
    const requests = ref<Request[]>([])

    // Reactive state to control unselecting
    const unselectRowsState = ref(false)

    // Filtering the metas
    const filterArchived = (items: any) => {
        return items.value.filter((item: any) => item.archived == false)
    }

    const mediaChannelsActive: ComputedRef<Mediachannel[]> = computed(() => filterArchived(mediaChannels))
    const contentTypesActive: ComputedRef<Contenttype[]> = computed(() => filterArchived(contentTypes))
    const audiencesActive: ComputedRef<Audience[]> = computed(() => filterArchived(audiences))
    const targetsActive: ComputedRef<Target[]> = computed(() => filterArchived(targets))
    const labelsActive: ComputedRef<Label[]> = computed(() => filterArchived(labels))

    const calStore = useCalendarStore()

    const mediaChannels = useLocalStorage('mediachannels', ref<Mediachannel[]>([]))
    const contentTypes = useLocalStorage('contenttypes', ref<Contenttype[]>([]))
    const audiences = useLocalStorage('audiences', ref<Audience[]>([]))
    const targets = useLocalStorage('targets', ref<Target[]>([]))
    const labels = useLocalStorage('labels', ref<Label[]>([]))
    // const requests = useLocalStorage('requests', ref<Request[]>([]))
    const communicationstatus = useLocalStorage('communicationstatus', ref<CommunicationstatusItem[]>([]))
    const holidays = useLocalStorage('holidays', ref<Holiday[]>([]))
    const comPoll = useLocalStorage('comPoll', ref<number>(Date.now()))
    const metaIcons = useLocalStorage('metaicons', ref<MetaIconItem[]>([]))

    // ANCHOR Views and output
    type ReadonlyTupleToUnion<T extends readonly any[]> = T[number]

    const viewTypeOptions = ['list', 'timeline', 'calendar', 'kanban', 'dashboard'] as const
    const viewType = useLocalStorage('commView', ref<ReadonlyTupleToUnion<typeof viewTypeOptions>>())
    const messages = ref<string[]>([])

    // ANCHOR Refresh store - - - - - - - - - - - -

    async function refreshCommuncations() {
        const perPage = 1500
        const communicationResponse = await apiGetCommunications(1, perPage)
        if (communicationResponse && communicationResponse.items?.length) {
            communications.value = communicationResponse.items as Communication[]
        }
        if (communicationResponse && communicationResponse.nextPage) {
            lazyloadCommuncations(communicationResponse.nextPage, perPage)
        }
        return true
    }
    const pendingCommunications = ref(false) // indicator for lazy loading

    async function lazyloadCommuncations(page: number = 1, perPage: number = 1000) {
        pendingCommunications.value = true
        const communicationResponse = await apiGetCommunications(page, perPage)
        if (communicationResponse && communicationResponse.items?.length) {
            communications.value.push(...(communicationResponse.items as Communication[]))
        }
        if (communicationResponse && communicationResponse.nextPage) {
            lazyloadCommuncations(communicationResponse.nextPage, perPage)
        } else {
            pendingCommunications.value = false
        }
    }

    async function refreshMediaChannels() {
        const mediaChannelResponse = await apiGetMediaChannels()
        if (mediaChannelResponse && mediaChannelResponse.items?.length) {
            mediaChannels.value = mediaChannelResponse.items as Mediachannel[]
        }
    }
    async function refreshRequests() {
        // FIXME need can read request
        refreshProgress.setLoading('Requests')
        const requestResponse = await apiGetRequests()
        if (requestResponse && requestResponse?.length) {
            requests.value = requestResponse as Request[]
        }
    }
    async function refreshContentTypes() {
        const contentTypeResponse = await apiGetContentTypes()
        if (contentTypeResponse && contentTypeResponse.items?.length) {
            contentTypes.value = contentTypeResponse.items as Contenttype[]
        }
    }
    async function refreshAudiences() {
        const audienceResponse = await apiGetAudiences()
        if (audienceResponse && audienceResponse.items?.length) {
            audiences.value = audienceResponse.items as Audience[]
        }
    }
    async function refreshTargets() {
        const targetResponse = await apiGetTargets()
        if (targetResponse && targetResponse.items?.length) {
            targets.value = targetResponse.items as Target[]
        }
    }
    async function refreshLabels() {
        const labelResponse = await apiGetLabels()
        if (labelResponse && labelResponse.items?.length) {
            labels.value = labelResponse.items as Label[]
        }
    }
    async function refreshCommunicationstatus() {
        const communicationstatusResponse = await apiGetCommunicationstatus()
        if (communicationstatusResponse && communicationstatusResponse.items?.length) {
            communicationstatus.value = communicationstatusResponse.items
        }
    }

    async function refreshHolidays() {
        const holidayResponse = await apiGetHolidays()
        if (holidayResponse && holidayResponse.items?.length) {
            holidays.value = holidayResponse.items as Holiday[]
        }
    }

    /* ANCHOR  Icons functions */
    async function refreshMetaIcons() {
        refreshProgress.setLoading('Icons')
        const iconsResponse = await apiGetMetaIcons()
        if (iconsResponse.items) {
            metaIcons.value = iconsResponse.items
        }
    }

    function getLabelName(id: number) {
        return labels.value.find((l) => l.id === id)?.name
    }

    onMounted(() => {
        const _registerComm = refreshProgress.registerRefreshFunction('comm')
        _registerComm('mediachannels', refreshMediaChannels)
        _registerComm('contenttypes', refreshContentTypes)
        _registerComm('audiences', refreshAudiences)
        _registerComm('targets', refreshTargets)
        _registerComm('labels', refreshLabels)
        _registerComm('communicationstatus', refreshCommunicationstatus)
        _registerComm('communications', refreshCommuncations)
        _registerComm('holidays', refreshHolidays)
        _registerComm('icons', refreshMetaIcons)
    })

    async function refreshStore(all: boolean = true) {
        //Option to only refresh communications

        //build a promise all of the code below

        if (!all) {
            await refreshCommuncations()
            return true
        } else {
            try {
                // Create an array of functions to execute concurrently
                await refreshMediaChannels()
                await refreshContentTypes()
                await refreshAudiences()
                await refreshTargets()
                await refreshLabels()
                await refreshCommunicationstatus()
                await refreshCommuncations()
                await refreshHolidays()
                await refreshMetaIcons()
                // const functions = [
                //     refreshCommuncations(),
                //     refreshMediaChannels(),
                //     refreshContentTypes(),
                //     refreshAudiences(),
                //     refreshTargets(),
                //     refreshLabels(),
                //     refreshCommunicationstatus(),
                // ]
                // // Wait for all promises to resolve
                // await Promise.all(functions)
                return true
            } catch (error) {
                // Handle any errors that may occur during the execution of the functions
                console.error('An error occurred:', error)
                return false
            }
        }
        comPoll.value = Date.now()
        communicationId.value = 0
    }

    async function resetStore() {
        communications.value = []
        mediaChannels.value = []
        requests.value = []
        contentTypes.value = []
        audiences.value = []
        targets.value = []
        labels.value = []
        holidays.value = []
        communicationstatus.value = []
        communication.value = undefined
        communicationId.value = 0
    }

    const populatedStore = computed(() => {
        return (
            communications.value.length > 0 &&
            mediaChannels.value.length > 0 &&
            contentTypes.value.length > 0 &&
            audiences.value.length > 0 &&
            targets.value.length > 0 &&
            labels.value.length > 0 &&
            communicationstatus.value.length > 0 &&
            holidays.value.length > 0 &&
            metaIcons.value.length > 0
        )
    })

    /* DEAFULT functions - - - - - - - - - */

    /* ANCHOR Communication functions - - - - - - - - - - - - */
    const loading = ref(false)
    // REVIEW op deze manier gaat hij niet goed in de localStorage, maar geeft GEEN Typescript errors.
    const communication = useLocalStorage('communication', ref<CommunicationItem>())
    // REVIEW op deze manier gaat hij als object in de localStorage, maar geeft WEL Typescript errors.
    // const communication = useLocalStorage(
    //     'communication',
    //     ref<CommunicationItem | {}>({})
    // )

    //const communication = ref<CommunicationDetailsItem>()
    const initialCom = {
        id: 0,
        name: '',
        start: null,
        end: null,
        status_id: 0,
        created_at: 0,
        updated_at: 0,
    }
    //const communication = ref<CommunicationDetailsItem>()
    const communicationId = useLocalStorage('communicationId', ref<number>(0))
    const communicationDialog = reactive({
        mode: 'create', // create | read | update | delete
        show: false,
    })
    // set a watcher on the id of the communication
    watch(
        communicationId,
        (newId: number | undefined, oldId: number | undefined) => {
            if (typeof newId === 'undefined') return
            if (newId === oldId && typeof oldId !== 'undefined') return
            if (newId > 0) {
                getCommunication(newId)
            } else {
                //clearCommunication()
            }
        },
        { immediate: true }
    )

    function clearCommunication() {
        communication.value = initialCom
    }

    function communicationPopup(id: number | 0): void {
        communicationId.value = id
        communicationDialog.show = true
    }

    // REVIEW een functiew voor alle Metas: getEntry, setEntry, updateEntry
    // De agemene functie om een bestaande payload op te halen, te updaten en terug te geven

    function getCommunication(id: any) {
        const theId = parseInt(id)
        return communications.value.find((p) => p.id === theId)
    }

    async function doneCommunication(id: number, state: boolean) {
        try {
            const res = await apiDoneCommunication(id, { done: state })
            const comIndex = communications.value.findIndex((c) => c.id === id)
            if (res.success) communications.value[comIndex].done = state
            return true
        } catch (error) {
            throw error
            //messages.value.push('Communicatie niet gewijzigd')
        }
    }

    async function replaceCommunicationWorkflow(id: number, body: object) {
        try {
            const res = await apiReplaceCommunicationWorkflow(id, body)
            // console.log('Result: ', res)

            return res
            // const comIndex = communications.value.findIndex((c) => c.id === id)
            // if (res.success) communications.value[comIndex].done = state
            // return true
        } catch (error) {
            throw error
        }
    }

    async function communicationToGripp(id: number) {
        try {
            // REVIEW Needs fixing
            const res = await apiToGripp(id)
            //const res = true
            const comIndex = communications.value.findIndex((c) => c.id === id)
            // REVIEW Needs validation
            if (res) communications.value[comIndex].sentToGripp = true
            return true
        } catch (error) {
            throw error
        }
    }

    async function copyCommunication(id: number) {
        try {
            const res = await apiCopyCommunication(id)
            if (!res) throw new Error('No response')

            communications.value.unshift(res as any)
            return res
        } catch (error) {
            throw error
        }
    }

    /* ANCHOR ContentType functions - - - - - - - - - - - - */

    function getContentTypeById(id: number | 0): ContentTypeItem | {} {
        if (id === 0) return {}
        return contentTypes.value.find((p) => p.id === id) || {}
    }

    /* ANCHOR  MediaChannels functions */

    function getMediaChannelById(id: number | 0): MediaChannelItem | {} {
        if (id === 0) return {}
        return mediaChannels.value.find((p) => p.id === id) || {}
    }

    /* ANCHOR  Audience functions */

    function getAudienceById(id: number | 0): AudienceItem | {} {
        if (id === 0) return {}
        return audiences.value.find((p) => p.id === id) || {}
    }

    /* ANCHOR  Target functions */

    function getTargetById(id: number | 0): TargetItem | {} {
        if (id === 0) return {}
        return targets.value.find((p) => p.id === id) || {}
    }

    /* ANCHOR  Label functions */

    function getLabelById(id: number | 0): LabelItem | {} {
        if (id === 0) return {}
        return labels.value.find((p) => p.id === id) || {}
    }

    /* ANCHOR Meta Icons */

    /* ANCHOR  Communicationstatus functions */

    function getCommunicationstatusById(id: number | 0): CommunicationstatusItem | {} {
        if (id === 0) return {}
        return communicationstatus.value.find((p) => p.id === id) || {}
    }

    async function createCommunicationstatus(body: CreateCommunicationstatusBody) {
        const statusResponse = await apiCreateCommunicationstatus(body)
        if (statusResponse) {
            communicationstatus.value.unshift(statusResponse)

            await communicationstatus.value
                .sort((a, b) => {
                    if (!a.sortOrder || !b.sortOrder) return 0
                    return a.sortOrder - b.sortOrder
                })
                .forEach((status, index) => {
                    const sort = (status.sortOrder = index + 10)
                    const id = status.id || 0
                    updateCommunicationstatus(id, { sortOrder: sort })
                })
            return true
        } else {
            return false
        }
    }

    async function updateCommunicationstatus(id: number, body: CreateCommunicationstatusBody) {
        const communicationstatusResponse = await apiUpdateCommunicationstatusById(id, body)
        if (communicationstatusResponse) {
            const index = communicationstatus.value.findIndex((d) => d.id === id)
            communicationstatus.value[index] = communicationstatusResponse
        }
        return true
    }

    async function deleteCommunicationstatus(id: number) {
        const labelResponse = await apiDeleteCommunicationstatusById(id)
        if (labelResponse) {
            communicationstatus.value = communicationstatus.value.filter((cs) => {
                return cs.id !== id
            })
            return true
        } else {
            return false
        }
    }

    const computedCommNameMap = computed(() => {
        return communications.value.map((comm) => {
            return {
                id: comm.id,
                name: comm.name,
            }
        })
    })

    const computedCommColorMap = computed(() => {
        return communications.value.map((comm) => {
            return {
                id: comm.id,
                colorCode: calStore.projects.find((p) => p.id === comm.project_id)?.colorCode,
            }
        })
    })

    /*
        Communications have projects, which are part of calendars.
        Some calendars are passive for some users.
        When a project is part of only passive calendars, the communication is passive and has a read-only status.
    */
    const computedPassiveProjectIds = computed(() => {
        return calStore.projects
            .filter((project) => {
                // console.log('project', project.calendar_id)
                if (!project.calendar_id?.length) return false
                return project.calendar_id?.every((calId) => {
                    return calStore.computedPassiveCalendarIds.includes(calId)
                })
            })
            .map((project) => project.id)
    })
    const computedPassiveCommunicationIds = computed(() => {
        return communications.value
            .filter((comm) => {
                return computedPassiveProjectIds.value.includes(comm.project_id)
            })
            .map((comm) => comm.id)
    })

    const communicationCount = computed(() => {
        return communications.value.length
    })

    function getCommName(id: number) {
        return computedCommNameMap.value.find((c) => c.id === id)?.name
    }

    function getCommColor(id: number) {
        return computedCommColorMap.value.find((c) => c.id === id)?.colorCode
    }

    return {
        communications,
        requests,
        loading,
        computedCommColorMap,
        getCommColor,
        getCommName,
        getLabelName,
        messages,
        viewType,
        viewTypeOptions,
        communication,
        communicationCount,
        pendingCommunications,
        communicationId,
        comPoll,
        communicationDialog,
        mediaChannels,
        contentTypes,
        audiences,
        targets,
        labels,
        holidays,
        communicationstatus,
        mediaChannelsActive,
        contentTypesActive,
        audiencesActive,
        targetsActive,
        labelsActive,
        metaIcons,
        refreshStore,
        resetStore,
        refreshRequests,
        refreshLabels,
        refreshMetaIcons,
        populatedStore,
        clearCommunication,
        communicationPopup,
        doneCommunication,
        replaceCommunicationWorkflow,
        communicationToGripp,
        copyCommunication,
        getContentTypeById,
        getMediaChannelById,
        getAudienceById,
        getTargetById,
        getLabelById,
        //getHolidayById,
        getCommunication,
        getCommunicationstatusById,
        createCommunicationstatus,
        updateCommunicationstatus,
        deleteCommunicationstatus,
        refreshCommunicationstatus,
        refreshCommuncations,
        computedPassiveCommunicationIds,
        computedPassiveProjectIds,
        unselectRowsState,
    }
})
