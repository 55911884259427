<script setup lang="ts">
    import { ref, computed, onMounted } from 'vue'
    import { useOrganisationStore } from '@stores/organisationStore'
    import { useAuthStore } from '@stores/authStore'
    import { storeToRefs } from 'pinia'
    import releaseNotes from '@/release/notes.json'
    import images from '@/utilities/images'

    import { useRouter } from 'vue-router'
    const router = useRouter()

    const orgStore = useOrganisationStore()
    const { organisation } = storeToRefs(orgStore)

    const authStore = useAuthStore()
    const { me, webRoles, subscription } = storeToRefs(authStore)

    const role = webRoles.value.find((role) => role.id === me.value.webrole_id)?.name || 'unknown'
    const org = organisation.value.companyName || 'unknown'
    const logoSrc = computed(() => organisation.value?.logo_url?.path)

    const rn = ref(releaseNotes)
    const latestNotesVersion = computed(() => {
        if (!rn.value || !rn.value.length) {
            return
        }
        return rn.value.reduce((prev: any, current: any) => {
            const av = prev.version
                .split('.')
                .map((v: string) => {
                    let nr = parseInt(v, 10)
                    return nr.toString().padStart(2, '0')
                })
                .join('')
            const bv = current.version
                .split('.')
                .map((v: string) => {
                    let nr = parseInt(v, 10)
                    return nr.toString().padStart(2, '0')
                })
                .join('')

            return av > bv ? prev : current
        }).version
    })

    // get application version from .env
    const build = import.meta.env.VITE_BUILD || '0.0.0'
    const stage = import.meta.env.VITE_ENVIRONMENT || 'prod'
    const data_source = import.meta.env.VITE_X_DATASOURCE || null
    const data_branch = import.meta.env.VITE_X_BRANCH || null

    const data_source_live = data_source === 'production' || data_source === 'live'
    const data_branch_live = data_branch === 'production' || data_branch === 'beta'

    const checkInSync = ref(true)
    onMounted(() => {
        if (latestNotesVersion.value && latestNotesVersion.value !== build) {
            console.error('CK Version : You do not have release notes for this version build.')
            checkInSync.value = false
        }
    })
</script>

<template>
    <footer class="footer d-print-none">
        Contentkalender
        <template v-if="stage !== 'prod'">
            <div class="d-flex flex-row align-center ms-2 do-click">
                <v-btn size="small" class="px-1" variant="text" color="grey" @click="router.push({ name: 'releasenotes' })">
                    <v-tooltip activator="parent" theme="light" location="top">Build version</v-tooltip>
                    <i class="fa-solid fa-flag"></i><span v-if="stage !== 'prod'" class="ps-1" :style="!checkInSync ? 'color:red' : 'color: inherit'">{{ build }}</span
                    ><span v-else class="ps-1">Contentkalender {{ build }}</span>
                </v-btn>
                <v-btn size="small" class="px-1" variant="text" color="grey" @click="router.push({ name: 'releasenotes' })">
                    <v-tooltip activator="parent" theme="light" location="top">Stage</v-tooltip>
                    <i class="fa-solid fa-seedling"></i><span class="ps-1">{{ stage }}</span>
                </v-btn>
                <template v-if="stage !== 'beta'">
                    <v-btn size="small" class="px-1" variant="text" :color="data_source_live ? 'red' : 'grey'">
                        <v-tooltip activator="parent" theme="light" location="top">Data source</v-tooltip>
                        <i class="fa-solid fa-database"></i><span class="ps-1">{{ data_source || '' }}</span>
                    </v-btn>
                    <v-btn size="small" class="px-1" variant="text" :color="data_branch_live ? 'red' : 'grey'">
                        <v-tooltip activator="parent" theme="light" location="top">API version</v-tooltip>
                        <i class="fa-solid fa-code-branch"></i><span class="ps-1">{{ data_branch || '' }}</span>
                    </v-btn>
                    <v-btn size="small" class="px-1" variant="text" color="grey" @click="router.push({ name: 'authtest' })">
                        <v-tooltip activator="parent" theme="light" location="top">{{ me.id }}: {{ me.emailAddress }}</v-tooltip>
                        <i class="fa-solid fa-user"></i><span class="ps-1">{{ role }}</span>
                    </v-btn>
                    <v-btn size="small" class="px-1" variant="text" color="grey">
                        <v-tooltip activator="parent" theme="light" location="top">Subscription: {{ subscription }}</v-tooltip>
                        <i class="fa-solid fa-user"></i><span class="ps-1">{{ org }}</span>
                    </v-btn>
                </template>
                <div class="preview-logo">
                    <img :src="logoSrc ? logoSrc : images.logo" alt="logo" />
                </div>
            </div>
        </template>
    </footer>
</template>

<style scoped lang="scss">
    .footer {
        position: fixed;
        padding-inline: 2.2rem;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 3rem;
        /* create a gradient background right is gret left is transparent */
        background: linear-gradient(to right, transparent, var(--color-grey-100));
        font-size: 0.8rem;
        color: var(--color-grey-500);
        text-transform: uppercase;
        display: flex;
        justify-content: right;
        align-items: center;
        pointer-events: none;

        .do-click {
            cursor: pointer;
            pointer-events: visible;
        }

        .preview-logo {
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            height: 20px;
            border: 1px solid transparent;
            width: auto;
            overflow: hidden;

            img {
                object-fit: contain;
                object-position: right bottom;
                height: 100%;
                width: 100%;
            }
        }
    }
</style>
