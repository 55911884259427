import { type Communication, type Api } from './_apiTypes'
import { apiCall } from './apiCall'
import { RequestBody, ResponseType } from './apiCall'

type ArrElement<ArrType> = ArrType extends (infer ElementType)[] ? ElementType : never

type CommunicationResponse = ResponseType<'/communication', 'get'>
type CommunicationItem = ArrElement<CommunicationResponse['items']>
type CommunicationDetailsItem = ResponseType<'/communication/0', 'get'>
type CommunicationCreateBody = RequestBody<'/communication', 'post'>
type CommunicationUpdateBody = RequestBody<'/communication/0', 'post'>
type CommunicationDoneBody = RequestBody<'/communication/0/done', 'post'>
type CommunicationDeleteResponse = ResponseType<'/communication/0', 'delete'>
type CommunicationBulkEditBody = RequestBody<'/communication/update/bulk', 'post'>
type Communicationrevision = ResponseType<'/communication/0/revision', 'get'>
type MetaIcon = ResponseType<'/meta/favicon', 'get'>

type CommunicationReplaceWorkflow = RequestBody<'/communication/0/workflow', 'post'>

type CommunicationrevisionItem = ArrElement<Communicationrevision['items']>

type MetaIconItem = ArrElement<MetaIcon['items']>

// type RequestResponse = ResponseType<'/request', 'get'>
type RequestUpdateBody = RequestBody<'/request/0', 'post'>

type CommunicationDetailsResponse = CommunicationDetailsItem & Required<Pick<CommunicationDetailsItem, 'id'>>

// Get a list of multiple communications
async function apiGetCommunications(pageNum?: number | undefined, perPageNum?: number | undefined) {
    return await apiCall('/communication', 'get', { page: pageNum || 1, perPage: perPageNum || 1000 }, null)
}

//Get a single communication
function apiGetCommunication(id: number) {
    return apiCall(`/communication/${id}`, 'get', null, null)
}

// Update a single communication
function apiUpdateCommunication(id: number, body: Partial<Communication>) {
    return apiCall(`/communication/${id}`, 'post', null, body)
}

// Create a new communication
function apiCreateCommunication(body: Omit<Communication, 'id'>) {
    return apiCall(`/communication`, 'post', null, body as any)
}

//Delete a single communication
function apiDeleteCommunication(id: number) {
    return apiCall(`/communication/${id}`, 'delete', null, null)
}

function apiCopyCommunication(id: number) {
    return apiCall(`/communication/${id}/copy`, 'post', null, null) as Promise<Communication>
}

function apiDoneCommunication(id: number, body: CommunicationDoneBody) {
    return apiCall(`/communication/${id}/done`, 'post', null, body)
}

function apiReplaceCommunicationWorkflow(id: number, body: CommunicationReplaceWorkflow) {
    return apiCall(`/communication/${id}/workflow`, 'post', null, body as any)
}

function apiToGripp(id: number) {
    return apiCall(`/communication/${id}/gripp`, 'post', null, null)
}

function apiGetCommunicationattachments(communication_id: number) {
    return apiCall(`/communication/${communication_id}/attachments`, 'get', {}, null)
}

function apiGetCommunicationattachmentsUUID(uuid: string) {
    return apiCall(`/communicationattachment/uuid/${uuid}`, 'get', {}, null)
}

function apiDeleteCommunicationattachment(id: number) {
    return apiCall(`/communicationattachment/${id}`, 'delete', null, null)
}

function apiCreateCommunicationattachment(
    /*
        Argument body has type signature:
        {
            file: File,
            communication_id?: number,
            uuid?: string
        }
    */
    body: Omit<RequestBody<'/communicationattachment', 'post'>, 'file' | 'excludeContentType'> & {
        file: File
    }
) {
    // Split file and create new object called rest with remaining keys
    const { file, ...rest } = body

    // Create new post body that conforms to type signature of apiCall
    const postBody = {
        file: file as unknown as string,
        ...rest,
        excludeContentType: true as const,
    }
    return apiCall('/communicationattachment', 'post', null, postBody)
}

function apiGetCommunicationrevisions(communication_id: number) {
    return apiCall(`/communication/${communication_id}/revision`, 'get', {}, null)
}

function apiCreateExport(startDate: string, endDate: string) {
    return apiCall(`/communication/export/${startDate}/${endDate}`, 'get', null, null)
}

function apiGetRequests() {
    return apiCall('/request', 'get', { page: 1, perPage: 1000 }, null)
}

function apiUpdateRequest(id: number, body: RequestUpdateBody) {
    return apiCall(`/request/${id}`, 'post', null, body)
}

function apiGetMetaIcons() {
    return apiCall(`/meta/favicon`, 'get', {}, null)
}

function apiCommunicationBulkEdit(body: CommunicationBulkEditBody) {
    return apiCall('/communication/update/bulk', 'post', null, body)
}

export {
    apiGetCommunications,
    apiGetCommunication,
    apiCreateCommunication,
    apiUpdateCommunication,
    apiDeleteCommunication,
    apiDoneCommunication,
    apiReplaceCommunicationWorkflow,
    apiToGripp,
    apiCopyCommunication,
    apiGetCommunicationattachments,
    apiGetCommunicationattachmentsUUID,
    apiCreateCommunicationattachment,
    apiDeleteCommunicationattachment,
    apiGetCommunicationrevisions,
    apiCreateExport,
    apiGetRequests,
    apiUpdateRequest,
    apiGetMetaIcons,
    apiCommunicationBulkEdit,
}
export type {
    CommunicationResponse,
    CommunicationItem,
    CommunicationDetailsItem,
    CommunicationCreateBody,
    CommunicationUpdateBody,
    CommunicationDeleteResponse,
    CommunicationDetailsResponse,
    CommunicationrevisionItem,
    CommunicationReplaceWorkflow,
    CommunicationBulkEditBody,
    MetaIconItem,
}

//@ts-expect-error
const communicationApi = {
    get: apiGetCommunication,
    create: apiCreateCommunication,
    update: apiUpdateCommunication,
    delete: apiDeleteCommunication,
} as Api<Communication>

export default communicationApi
